import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  copyright
  Allrightsreserved
  constructor( private translate:TranslateService) { }

  ngOnInit() {
    this.translate.get("copyright").subscribe((res: string) => {        
      this.copyright = res;      
    });
    
    this.translate.get("Allrightsreserved").subscribe((res: string) => {        
      this.Allrightsreserved = res;      
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // console.log("event", event)
       this.translate.use(event.lang)  
       
       this.translate.get("copyright").subscribe((res: string) => {
        //console.log(res);
        this.copyright=res;      
       });

       this.translate.get("Allrightsreserved").subscribe((res: string) => {
        //console.log(res);
        this.Allrightsreserved=res;      
       });

    });
  }

}
