import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import{GoogleAnalyticsService} from '../../google-analytics.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  cvUrl
  quote 
  SoftEng
  hire
  download

  constructor(private profileService:ProfileService , private translate:TranslateService , private googleAnalyticsService:GoogleAnalyticsService) { }

  ngOnInit() {
    this.cvUrl =  this.profileService.resumeurl

    this.translate.get("quote").subscribe((res: string) => {
        //console.log(res);
        this.quote=res;
    });

    this.translate.get("hire").subscribe((res: string) => {
      //console.log(res);
      this.hire=res;
  });

   this.translate.get("download").subscribe((res: string) => {
    //console.log(res);
     this.download=res;
   });

    this.translate.get("SoftEng").subscribe((res: string) => {
      //console.log(res);
      this.SoftEng=res;
    });    

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // console.log("event", event)
       this.translate.use(event.lang)
       this.translate.get("quote").subscribe((res: string) => {
      //   console.log(res);
         this.quote=res;         
     });

     this.translate.get("SoftEng").subscribe((res: string) => {
      //console.log(res);
      this.SoftEng=res;
    });

    this.translate.get("hire").subscribe((res: string) => {
      //console.log(res);
      this.hire=res;
  });

   this.translate.get("download").subscribe((res: string) => {
    //console.log(res);
     this.download=res;
   });
 });

  }
  goto() {
  
    var element = document.getElementById("contentView");
  
  element.scrollIntoView();
  }

  downlaod()
  {
    /*this
     .googleAnalyticsService
     .eventEmitter("downlaod_CV", "hire", "button", "click", 1);*/
  }
  gotoCV()
  {
    window.open(this.cvUrl)
  }
}
