import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceApiService {
  
  private baseUrl ="https://chatbotapi-sref.onrender.com/api/v1/ask";
  constructor(private HttpClient:HttpClient) { }


  public sendMessage(message:string,xy:string):any
  {
    let data = {
      message: message,
      xy: xy
    }

    const searchUrl=`${this.baseUrl}`;
    return this.HttpClient.post<any>(
      searchUrl,
      data,
     {
      headers :
      {
      'Content-Type': 'application/json'     
      },
      withCredentials: true
    }
   );
  }
}
