import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  SafeResourceUrl : SafeResourceUrl
  constructor(private sanitizer: DomSanitizer ) { }
  transform(url) {
       return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      //this.SafeResourceUrl=this.sanitizer.bypassSecurityTrustResourceUrl(url);
    //  return this.SafeResourceUrl
  }

}