import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ProfileComponent } from './profile.component';
//import { HeaderComponent } from './header/header.component';
//import { FooterComponent } from './footer/footer.component';
//import { IntroComponent } from './intro/intro.component';
import { AboutComponent } from './about/about.component';
import { SkillsComponent } from './skills/skills.component';
import { ExperienceComponent } from './experience/experience.component';
import { EducationComponent } from './education/education.component';
import { ReferenceComponent } from './reference/reference.component';
import { ContactComponent } from './contact/contact.component';
import { NgxSpinnerModule } from '../../../node_modules/ngx-spinner';
import { ProjectsComponent } from './projects/projects.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { SplitPipe } from './split.pipe';
import { SafePipe } from '../profile/SafePipe';
import { NgForm } from "@angular/forms";
import { FormControl, Validators } from '@angular/forms';
import { NgxMatErrorsModule} from 'ngx-mat-errors';
import { MatFormFieldModule} from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { CertificateComponent } from './certificate/certificate.component';
import { ServiceComponent } from './service/service.component';
//import { ChatbotComponent } from './chatbot/chatbot.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  imports: [
    CommonModule,
    FormsModule,  
    SnotifyModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule, 
    NgxMatErrorsModule,
    MatFormFieldModule,    
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    ProfileComponent,
    //HeaderComponent,
   // FooterComponent,
    //IntroComponent,
    AboutComponent,
    SkillsComponent,
    ExperienceComponent,
    EducationComponent,
    ReferenceComponent,
    ContactComponent,
    ProjectsComponent,
    SplitPipe,
    CertificateComponent,
    ServiceComponent,
   // ChatbotComponent
   // SafePipe
  ],
  providers: [
    { provide: 'SnotifyToastConfig', 
    useValue: ToastDefaults},
    SnotifyService
  ],
  exports : [TranslateModule]
})
export class ProfileModule { }
