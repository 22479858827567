import { Component , OnInit, NgZone, ViewChild  } from '@angular/core';
import { NgxSpinnerService } from '../../node_modules/ngx-spinner';
import {TranslateService} from '@ngx-translate/core';

import{Router, NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';
declare let gtag: Function;

import { MatDialog } from '@angular/material/dialog';
import { ChatbotComponent } from './profile/chatbot/chatbot.component';


@Component({
  selector: 'app-root',
 // template: '<router-outlet></router-outlet>'
 templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    public router: Router,
    public dialog: MatDialog,
    private ngZone:NgZone
   
    ) { 
      translate.setDefaultLang('en');
      const navEndEvent$ = router.events.pipe(
        filter(e => e instanceof NavigationEnd)
      );
      navEndEvent$.subscribe((e: NavigationEnd) => {
        gtag('config', 'UA-165130392-1', {'page_path':e.urlAfterRedirects}); //gtag added here
        gtag('config', 'G-KR4QBCKDZ5', {'page_path':e.urlAfterRedirects});
      });
      
    }

    useLanguage(language: string) {
      this.translate.use(language);
    }
  

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
        this.spinner.hide();
    }, 2000);
    setTimeout(() => {
      this.isOpen=true;
  }, 5000);
  }
  @ViewChild(ChatbotComponent, { static: true }) chatbotComponent: ChatbotComponent;

  isOpen:boolean = false;
  openChatbox(): void {
    this.chatbotComponent.openChatbox();
    this.isOpen = true;
    console.log(this.isOpen)
   

   /*const dialogRef = this.dialog.open(ChatbotComponent, {
     
      // Add any additional configuration options here
    });*/
  }
}
