import { BrowserModule } from '@angular/platform-browser';
import { NgModule   } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { ProfileModule } from './profile/profile.module';
import { AboutComponent } from './profile/about/about.component';
import { ProjectsComponent } from './profile/projects/projects.component';
import { ExperienceComponent } from './profile/experience/experience.component';
import { SkillsComponent } from './profile/skills/skills.component';
import {EducationComponent } from './profile/education/education.component';
import {ReferenceComponent } from './profile/reference/reference.component';
import {ContactComponent } from './profile/contact/contact.component';
//import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './profile/footer/footer.component';
import { HeaderComponent } from './profile/header/header.component';
import { NgxSpinnerModule } from '../../node_modules/ngx-spinner';
import { IntroComponent } from './profile/intro/intro.component';
//import { HttpClient } from '@angular/common/http';
import { SafePipe } from './profile/SafePipe';
import { NgForm } from "@angular/forms";
import { FormControl, Validators } from '@angular/forms';
import {  NgxMatErrorsModule} from 'ngx-mat-errors';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { SplitPipe } from './profile/split.pipe';


import {GoogleAnalyticsService} from './google-analytics.service';
import { SideBarAdminComponent } from './side-bar-admin/side-bar-admin.component';

import { LightboxModule } from 'ngx-lightbox'; 
//import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { RecaptchaModule, RecaptchaFormsModule  } from 'ng-recaptcha';
import { ServiceComponent } from './profile/service/service.component';
import { ChatbotComponent } from './profile/chatbot/chatbot.component';

import { FormsModule , ReactiveFormsModule} from '@angular/forms';
const routes: Routes = [
 

    {path:'',component: ProfileComponent},
    {path:'about', component: AboutComponent },
    {path:'experience', component:ExperienceComponent },
    {path:'projects',component:ProjectsComponent},
    {path:'skills',component:SkillsComponent},
    {path:'education',component:EducationComponent},
    //{path:'reference',component:ReferenceComponent},
    {path:'contact',component:ContactComponent},
    {path:'service',component:ServiceComponent},
    {path:'**',redirectTo:'', pathMatch:"full"}
    
];

const config: ExtraOptions = {
  useHash: false//,
  //scrollPositionRestoration:'enabled'
};
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IntroComponent,
    ChatbotComponent,
    //,
    SafePipe,
    SideBarAdminComponent,
    //SplitPipe
   
  ],entryComponents: [ChatbotComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, config),
    ProfileModule,
    HttpClientModule,
    // HttpClient,
    NgxSpinnerModule,
    NgxMatErrorsModule,
    MatFormFieldModule,
    SnotifyModule,
    LightboxModule,
    RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    RecaptchaModule, 
    RecaptchaFormsModule,
    MatDialogModule,
    MatIconModule
    ,FormsModule,ReactiveFormsModule,MatProgressSpinnerModule
  ],
  
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
    GoogleAnalyticsService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
  },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


