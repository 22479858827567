
<div id="buttonopen" class="chatbox-box">
  <button mat-fab class="btnChat" (click)="openChatbox()">
    <mat-icon style=" font-size: 32px;  display: inline-block; margin-top: 20%;      width: auto;">live_help</mat-icon>
  </button>
</div> 
<div *ngIf="isOpen" class="chatbox">
  <div class="chat-header">Ask me</div>
  <div class="chat-content" #chatContent>
    <div *ngFor="let chatMessage of chatMessages" [ngClass]="{'container': true, 'darker': chatMessage.isResponse}" >
      <p>{{ chatMessage.message }}</p>
      <span class="time-{{ chatMessage.isResponse ? 'left' : 'right' }}">{{ chatMessage.timestamp | date:'HH:mm' }}</span>
    </div>
    <div *ngIf="loading" >
        <mat-spinner class="loading-overlay"  diameter="28"></mat-spinner>
    </div>
  </div>

  <form name="form" (ngSubmit)="f.form.valid ; f.resetForm()" #f="ngForm">
    <div class="chat-input">
      <input type="text" [(ngModel)]="message" [formControl]="messageFormControl" placeholder="Type your message...">
      <button (click)="sendMessage()">Send</button>
    </div>
  </form>
  <button mat-raised-button color="primary" (click)="closeChatbox()">Close</button>
</div>
    
   
