import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from '../../../node_modules/ngx-spinner';
import {TranslateService} from '@ngx-translate/core';

import{Router, NavigationEnd} from '@angular/router';
declare let gtag: Function;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    public router: Router
  ) { 
    translate.setDefaultLang('en');    
     /* this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
            gtag('config', 'UA-165130392-1', 
                  {
                    'page_path': event.urlAfterRedirects
                  }
                 );
         }
      })*/
    
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
        this.spinner.hide();
    }, 2000);
  }

}
