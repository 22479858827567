import { Component, Input, ChangeDetectorRef, OnInit, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import { ServiceApiService } from "../service-api.service";
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatContent', { static: false }) chatContent: ElementRef;

  @Input() isOpen: boolean = false;

  messageFormControl = new FormControl("", [
    Validators.required
  ]);

  message: any = "";

  xy: string = "";

  loading: boolean = false;

  constructor(private apiService: ServiceApiService) { }

  ngOnInit(): void {
    this.xy = this.makeid(7);
    this.chatMessages.push({
      message: "Hello! 👋 Welcome to my portfolio website! I'm here to help and answer any questions you may have. How can I assist you today?",
      isResponse: true,
      timestamp: new Date()
    });
  }

  closeChatbox(): void {
    this.isOpen = false;
    document.getElementById("buttonopen").style.display = "block";
  }

  openChatbox(): void {
    this.isOpen = true;
    document.getElementById("buttonopen").style.display = "none";
    this.scrollToBottom();
  }

  makeid(length: number): any {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  chatMessages: { message: string, isResponse: boolean, timestamp: Date }[] = [];

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      if (this.chatContent && this.chatContent.nativeElement) {
        this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight;
      }
    } catch (err) {
      console.error(err);
    }
  }

  sendMessage() {
    const message: string = this.messageFormControl.value;
  
    if (message !== undefined && message.length !== 0) {
      this.loading = true;
  
      this.apiService.sendMessage(message, this.xy).subscribe(
        (data) => {
          this.chatMessages.push({ message: message, isResponse: false, timestamp: new Date() });
  
          // TODO: Call your chatbot service to get a response
          const botResponse = data.response; // Replace this with actual bot response
  
          // Add bot response to the chat history
          this.chatMessages.push({ message: botResponse, isResponse: true, timestamp: new Date() });
  
          this.loading = false;
        },
        (err) => {
          console.log("err : ", err);
          this.loading = false;
        }
      );
    }
  
    this.message = "";
    this.messageFormControl.setValue("");
  }
}
