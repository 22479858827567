import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
  
})
export class CertificateComponent implements OnInit {

  
  _albums: Array<any>= [
    { src: 'assets/certif/CourseraSpringBoot.jpg', caption: '', thumb: 'assets/certif/CourseraSpringBoot.jpg',title:'Web Development with Java Spring Framework',source:'Coursera' },
    { src: 'assets/certif/GCP.jpg', caption: '', thumb: 'assets/certif/GCP.jpg',title:'Building Scalable Java Microservices with Spring Boot and Spring Cloud',source:'Coursera' },
    { src: 'assets/certif/Aisec.jpg', caption: '', thumb: 'assets/certif/Aisec.jpg',title:'Leadership Development Experience',source:'AIESEC' },
    { src: 'assets/certif/cloud native app.jpeg', caption: '', thumb: 'assets/certif/cloud native app.jpeg',title:'Leadership Development Experience',source:'Coursera' },
    { src: 'assets/certif/create-serverless-app.jpeg', caption: '', thumb: 'assets/certif/create-serverless-app.jpeg',title:'Leadership Development Experience',source:'Coursera' },
    { src: 'assets/certif/docker.jpeg', caption: '', thumb: 'assets/certif/docker.jpeg',title:'Leadership Development Experience',source:'Coursera' },
    { src: 'assets/certif/Aisec.jpg', caption: '', thumb: 'assets/certif/Aisec.jpg',title:'Leadership Development Experience',source:'Coursera' }
  ]; 
 
  certifSection
  constructor(private _lightbox: Lightbox,private translate:TranslateService) {
        
  } 

  ngOnInit(): void {
    this.translate.get("certificates").subscribe((res: any) => {
      //console.log(res);
      this._albums=res;      
    });
    
    this.translate.get("CertifSection").subscribe((res: string) => {
      //console.log(res);
      this.certifSection=res;      
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // console.log("event", event)
       this.translate.use(event.lang)
  
       this.translate.get("certificates").subscribe((res: any) => {
        //console.log(res);
        this._albums=res;      
      });
       
    this.translate.get("CertifSection").subscribe((res: string) => {
      //console.log(res);
      this.certifSection=res;      
    });
  })
  
}
  
  open(index: number): void { // open lightbox 
  this._lightbox.open(this._albums, index); 
  } 
  
  close(): void { // close lightbox programmatically 
  this._lightbox.close(); 
  }


} 