import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
Education
CSED
CSE
PHSEME

LI1CSE
LI2CSE
LI3CSE

TCP

LI1TCP
LI2TCP
LI3TCP

BDTS
HSKM
LI1HSKM
LI2HSKM

  constructor( private translate:TranslateService) { }

  ngOnInit() {
    this.translate.get("Education").subscribe((res: string) => {
      //console.log(res);
      this.Education=res;      
    }); 

    this.translate.get("CSED").subscribe((res: string) => {
      //console.log(res);
      this.CSED=res;      
    });
    
    this.translate.get("CSE").subscribe((res: string) => {
      //console.log(res);
      this.CSE=res;      
    });

    this.translate.get("PHSEME").subscribe((res: string) => {
      //console.log(res);
      this.PHSEME=res;      
    });

    this.translate.get("LI1CSE").subscribe((res: string) => {
      //console.log(res);
      this.LI1CSE=res;      
    });

    this.translate.get("LI2CSE").subscribe((res: string) => {
      //console.log(res);
      this.LI2CSE=res;      
    });

    this.translate.get("LI3CSE").subscribe((res: string) => {
      //console.log(res);
      this.LI3CSE=res;      
    });

    this.translate.get("TCP").subscribe((res: string) => {
      //console.log(res);
      this.TCP=res;      
    });

    
    this.translate.get("LI1TCP").subscribe((res: string) => {
      //console.log(res);
      this.LI1TCP=res;      
    });

    this.translate.get("LI2TCP").subscribe((res: string) => {
      //console.log(res);
      this.LI2TCP=res;      
    });

    this.translate.get("LI3TCP").subscribe((res: string) => {
      //console.log(res);
      this.LI3TCP=res;      
    });

    this.translate.get("BDTS").subscribe((res: string) => {
      //console.log(res);
      this.BDTS=res;      
    });

    this.translate.get("HSKM").subscribe((res: string) => {
      //console.log(res);
      this.HSKM=res;      
    });

    this.translate.get("LI1HSKM").subscribe((res: string) => {
      //console.log(res);
      this.LI1HSKM=res;      
    });

    this.translate.get("LI2HSKM").subscribe((res: string) => {
      //console.log(res);
      this.LI2HSKM=res;      
    });

  

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // console.log("event", event)
       this.translate.use(event.lang)

       this.translate.get("Education").subscribe((res: string) => {
        //console.log(res);
        this.Education=res;      
      }); 
  
      this.translate.get("CSED").subscribe((res: string) => {
        //console.log(res);
        this.CSED=res;      
      });
      
      this.translate.get("CSE").subscribe((res: string) => {
        //console.log(res);
        this.CSE=res;      
      });
  
      this.translate.get("PHSEME").subscribe((res: string) => {
        //console.log(res);
        this.PHSEME=res;      
      });
  
      this.translate.get("LI1CSE").subscribe((res: string) => {
        //console.log(res);
        this.LI1CSE=res;      
      });
  
      this.translate.get("LI2CSE").subscribe((res: string) => {
        //console.log(res);
        this.LI2CSE=res;      
      });
  
      this.translate.get("LI3CSE").subscribe((res: string) => {
        //console.log(res);
        this.LI3CSE=res;      
      });
  
      this.translate.get("TCP").subscribe((res: string) => {
        //console.log(res);
        this.TCP=res;      
      });
  
      
      this.translate.get("LI1TCP").subscribe((res: string) => {
        //console.log(res);
        this.LI1TCP=res;      
      });
  
      this.translate.get("LI2TCP").subscribe((res: string) => {
        //console.log(res);
        this.LI2TCP=res;      
      });
  
      this.translate.get("LI3TCP").subscribe((res: string) => {
        //console.log(res);
        this.LI3TCP=res;      
      });
  
      this.translate.get("BDTS").subscribe((res: string) => {
        //console.log(res);
        this.BDTS=res;      
      });
  
      this.translate.get("HSKM").subscribe((res: string) => {
        //console.log(res);
        this.HSKM=res;      
      });
  
      this.translate.get("LI1HSKM").subscribe((res: string) => {
        //console.log(res);
        this.LI1HSKM=res;      
      });
  
      this.translate.get("LI2HSKM").subscribe((res: string) => {
        //console.log(res);
        this.LI2HSKM=res;      
      });
 });
  
  }

}
