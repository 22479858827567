<div class="profile-page">
    <div class="wrapper">
        <div class="page-header page-header-small" filter-color="green">
            <div class="page-header-image" data-parallax="true" style="background-image: url('assets/images/pic9.jpg');">
            </div>
            <div class="container">
                <div class="content-center">
                    <div class="cc-profile-image">
                        <a href="">
                            <img src="assets/images/profile.jpg" alt="Image" />                      
                        </a>
                    </div>
                    <!--<svg viewBox="0 0 100 100" width="auto" height="auto">
                        <defs>
                            
                          <path id="circle"
                            d="
                              M 50, 50
                              m -37, 0
                              a 37,37 0 1,1 74,0
                              a 37,37 0 1,1 -74,0"/>
                        </defs>
                        <text font-size="17">
                          <textPath xlink:href="#circle">
                            Born to develop ...                            
                          </textPath>                          
                        </text>    
                                                    
                      </svg>-->
                    <div class="h2 title">Bouraoui Ziche</div>
                    <p class="category text-white"> {{SoftEng}} </p>
                    <p class="category neonText" style="font-size: 0.7cm; font-family: Georgia, 'Times New Roman', Times, serif;"> F R E E L A N C E R </p>
                    <a class="btn btn-hire smooth-scroll mr-2" (click)="goto()" routerLink='/contact' data-aos="zoom-in" data-aos-anchor="data-aos-anchor">  {{hire}}  <img   style ="width: 20px; height: 20px;" src="assets/images/human-resources.png" alt="Image" />  </a>
                    <a class="btn btn-primary"  href={{cvUrl}} target="_blank" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">{{download}}  <img  style ="width: 20px; height: 20px;" src="assets/images/download-direto.png" alt="Image" />  </a>
                </div>
            </div>
            <br>
            
         <div class="section">
                  <div class="container">
                     <div class="button-container">                      
                       <div  style="padding-top: 1cm;">
                         <h4 style="font-size: 34px;">{{quote}}</h4>
                       </div>                        
                       <!-- <a target="_blank" href="http://codepen.io/Moslim/" class="white-mode">OTHER PENS</a> -->
                      </div> 
                </div>
            </div>
        </div>
    </div>
</div>