import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
//import {SafeResourceUrl } from '@angular/platform-browser';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {projectsM} from '../modelsClass/projectsM'

import { SafePipe } from '../SafePipe';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  config: any;
  projectsM : any=[];
  projects : any =[];
  Technology

  ProjectsTitle

  constructor(private profileService:ProfileService,
     private translate:TranslateService) 
     { 
    //  translate.setDefaultLang('en');

      // the lang to use, if the lang isn't available, it will use the current loader to get them
   //  translate.use('fr');
     }

  ngOnInit() {
    /*this.profileService.getProjects()*/
   // this.projects = Array.of(this.projects)

   //console.log(this.translate.currentLang)
  // console.log(this.translate.stream("PROJECTS"))
  //  console.log(this.translate.get("PROJECTS"))

    this.projectsM =   this.translate.get("projects").subscribe((res: string) => {
      //console.log(res);
      this.projects=res;
      
  });

  this.translate.get("Technology").subscribe((res: string) => {
    //console.log(res);
    this.Technology=res;    
});
this.translate.get("ProjectsTitle").subscribe((res: string) => {
  //console.log(res);
  this.ProjectsTitle=res;    
});

    
    this.config = {
      itemsPerPage: 6,
      currentPage: 1,
      totalItems: this.projects.length
    };

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
         // console.log("event", event)
          this.translate.use(event.lang)
          this.projectsM =   this.translate.get("projects").subscribe((res: string) => {
         //   console.log(res);
            this.projects=res;
            
        });

        this.translate.get("ProjectsTitle").subscribe((res: string) => {
          //console.log(res);
          this.ProjectsTitle=res;    
        });

        this.translate.get("Technology").subscribe((res: string) => {
          //console.log(res);
          this.Technology=res;    
      });
     });
    }

  pageChanged(event){
    this.config.currentPage = event;
  }
}
