import { Injectable, Pipe } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DomSanitizer  } from '@angular/platform-browser';
import { SafePipe } from './SafePipe';
//import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ProfileService   {
  otherskills() {
    
  }
   baseUrl = environment.baseUrl;
   SafePipe:SafePipe = new SafePipe(this.domSanitizer);
  constructor(
    private http: HttpClient, 
    private domSanitizer : DomSanitizer,
    //private toastr:ToastrService
    
     ) { }
 
     

 /* showSuccess(message, title){
      this.toastr.success(message, title)
  }
  
  showError(message, title){
      this.toastr.error(message, title)
  }
  
  showInfo(message, title){
      this.toastr.info(message, title)
  }
  
  showWarning(message, title){
      this.toastr.warning(message, title)
  }*/
  projects:any = [
    {
      id: 1,
      title: 'GYM subscribtion managment',
      desc: 'Desktop application to manage the subscription of GYM',
      livedemo: 'https://mehulk05.github.io/MyShop/#/',
      githurl: 'https://github.com/mehulk05/MyShop',
      mediumlink: 'https://itnext.io/read-data-from-google-spreadsheet-in-angular-209ee74b6cb4',
      imgUrl: 'https://youtu.be/SHedOrQxldo',
      tech: 'Java , JavaFx , Scenebuilder , MySQL '
    },

    {
      id: 2,
      title: 'CRUD WEB Application ',
      desc: 'CRUD functions with NODEJS and IONIC',
      livedemo: 'https://mehulk05.github.io/Blogapp-using-MERN/#/',
      githurl: 'https://github.com/mehulk05/Blogapp-using-MERN',
      mediumlink: 'https://itnext.io/blog-app-using-mern-stack-b0b4d69d7ea1',
      imgUrl: 'https://youtu.be/SHedOrQxldo',
      tech: 'IONIC 4, NODEJS , MySQL'
    },


    {
      id: 3,
      title: 'API ASP.net C# ',
      desc: 'Read Json file and did some caluculation depending of the Json file',
      livedemo: 'https://mehulk05.github.io/Blog-using-mean/#/',
      githurl: 'https://github.com/mehulk05/Blog-using-mean',
      mediumlink: 'https://itnext.io/crud-operation-using-mean-stack-7dfa2f51ec8c',
      imgUrl: 'https://youtu.be/SHedOrQxldo',
      tech: 'ASP.NET , C# '
    },

    {
      id: 4,
      title: 'Managment of road traffic web application',
      desc: 'Display the positions of accident and list of some details related to that , caluclate some statistique .This project was done during the Internship of graduation',
      livedemo: 'https://chatapp-using-node.herokuapp.com/',
      githurl: 'https://github.com/mehulk05/Chat-app-using-Nodejs',
      mediumlink: 'https://medium.com/weekly-webtips/building-a-multiroom-chat-application-in-node-js-8a8adca5acf2',
      imgUrl: 'https://youtu.be/SHedOrQxldo',
      tech: 'Ionic 4 , Angular 8 , SQL Developer '
    },


    {
      id: 5,
      title: 'Dots & boxes game',
      desc: 'Allow two persons to play together Dots & boxes online',
      livedemo: 'https://mehulk05.github.io/Medium-Blog-On-React-App#/',
      githurl: 'https://github.com/mehulk05/Medium-Blog-On-React-App',
      mediumlink: 'https://javascript.plainenglish.io/how-to-add-medium-blogs-on-your-react-app-a3898a729aad',
      imgUrl: 'https://youtu.be/SHedOrQxldo',
      tech: 'Angular 8, Firebase Realtimedatabase , SVG '
    }

  ]
  about2 = `Software Developer with 1.5+ years of experience in web develpment .
  Experienced in both part front and back end develpment .
  Expertise in Java 6+, Java2EE, Design Pattern, Maven, Hibernate, SpringBoot framebook,ORACL SQL Developer, Sybase, Postgres, MongoDB, Firebase, and MYSQL .
  Have good understanding of Angular 8+, IONIC 4+, Javascript, Node JS and UI with Responsive Designs  `

  about = "Creativity and Innovation are my life style, I believe that every problem have a solution, nothing is impossible."
  resumeurl = "https://drive.google.com/file/d/11DbaBuDkkE5HOpsg37ofkhmTlMzGm6ep/view?usp=sharing"

  skillsData: any = [
    {
      'id': '1',
      'skill': 'ANGULAR 7+',
      'progress': '75'
    },
    {
      'id': '2',
      'skill': 'HTML / CSS',
      'progress': '75'
    },
    {
      'id': '3',
      'skill': 'SPRING BOOT FRAMEWORK',
      'progress': '85'
    },
    {
      'id': '4',
      'skill': 'SQL / NoSQL ',
      'progress': '90'
    },
    {
      'id': '5',
      'skill': 'Docker',
      'progress': '80'
    },  
    {
      'id': '6',
      'skill': 'JAVA 8+',
      'progress': '90'
    }
    ,
    {
      'id': '7',
      'skill': 'Javascript',
      'progress': '80'
    }
    ,
    {
      'id': '5',
      'skill': 'CI/CD',
      'progress': '80'
    }
    ,
    {
      'id': '9',
      'skill': 'UML',
      'progress': '90'
    },
    
    {
      'id': '10',
      'skill': 'Swagger',
      'progress': '80'
    },
    
    {
      'id': '10',
      'skill': 'GraphQL',
      'progress': '80'
    }
  ];


 

  educationData: any = [
    {
      'id': '1',
      'from_to_year': '2019 - 2016',
      'education': 'Software Engineer',
      'stream':  'Learned and approved software developer skills',
      'info': `We saw various courses and modules in differents aspect security , mobile , web , BI , AI,.. .         
       Won the major Student Award 1 year . ` ,
      'institution': 'High School of Engineer in MONASTISR ESPRIM´S'
    },
    {
      'id': '2',
      'from_to_year': '2014 - 2016',
      'education': 'Technical Preparatory',
      'stream': 'Preparatory of Engineer education phase',
      'info': `Tense phase, contained various courses as preparation for Engineering phase .   
       Won the Major Student Award 2 on the two years .  ` ,
      'institution': 'High School of Engineer in MONASTISR ESPRIM´S'
    },
    {
      'id': '3',
      'from_to_year': '2011 - 2012',
      'education': 'Bachelor´s degree Technical Science',
      'stream': 'Technical Science',
      'institution': 'High School Ksibet El Mediouni',
      'info': `The Secondary aims at Maths , English , French, Science, Social Science, Computer science (algorithm).
       Graduated with honor mention `
    }
  ];
  exprienceData: any = [
    {
      id: 2,
      company: 'Ausy PT / Accenture',
      location: 'Portugal - Lisbon',
      timeline: 'Sep 2020 to Present',
      role: 'Java Developer',
      work: `Technologies : Java , Java2EE , SQL Server , Sybase , Palmyra framework . 
      Working as java developer. Responsible on creating , update and maintenance of Java applications  .
      Creating of dynamic report using actuate and Sybase , managing sql procedures responsable of returning the convenat data `

    },
    {
      id: 1,
      company: 'LEVEL33',
      location: 'Brasil - Brasilia ',
      timeline: 'apr 2019 to sept 2019',
      role: 'FullStack Developer (Internship of Graduation)',
      work: `Technologies : Angular , IONIC 4 , SQL Developer , Backbone js , Asp.net , C#  . 
      Achievement of website application for management of the road traffic and criminality for polices of Brasil(backbone js ,asp.net,c#,sqlserver) as fullstack developer .
      Leader of small team for cloning my Ionic projects methodology.
      Achievement of mobile and web application with Ionic 4 and Angular for the Front End side , ASP.Net C# for the web service (Backend) , SQL Server as DataBase .
      Achievement of web application of processing of Traffic Road tickets(vue js, sqlserver) worked on Front End side  `
    }
  ]
   contactus(data: any): Observable<any> {
     return this.http.post(this.baseUrl + 'contact', data);
   }

  skills(): Observable<any> {
    // return this.http.get(this.baseUrl + 'skills');
    return this.skillsData;
  }

  getProjects(): Observable<any> {
    // return this.http.get(this.baseUrl + 'skills');
    return this.projects;
  }
  education(): Observable<any> {
    // return this.http.get(this.baseUrl + 'education');
    return this.educationData;
  }

  exprience(): Observable<any> {
    // return this.http.get(this.baseUrl + 'exprience');
    return this.exprienceData;
  }
}
