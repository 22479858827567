<header>
    <div class="profile-page sidebar-collapse">
        <nav class="navbar navbar-expand-lg fixed-top navbar-transparent bg-primary" color-on-scroll="400">
            <div class="container">
                <div class="navbar-translate">
                   <!-- <a class="navbar-brand" href="#" rel="tooltip">Bouraoui's Portfolio</a> -->
                   <!-- reseau sociaux-->
             <div class="navbar-brand" >
                <a  class="btn btn-default btn-squar btn-lg btn-icon" href="https://www.facebook.com/monster.leebruce/" target="_blank" rel="tooltip" >
                    <i class="fa fa-facebook"></i>
                </a>
                <a class="btn btn-default btn-squar btn-lg btn-icon" href="https://www.instagram.com/bouraouiziche/" target="_blank" rel="tooltip" >
                    <i class="fa fa-instagram"></i>
                </a>
                <a class="btn btn-default btn-squar btn-lg btn-icon" href="https://www.linkedin.com/in/bouraoui-ziche/" target="_blank" rel="tooltip" >
                    <i class="fa fa-linkedin"></i>
                </a>
                <a class="btn btn-default btn-squar btn-lg btn-icon" href="https://github.com/sag3ellil/" target="_blank" rel="tooltip" >
                    <i class="fa fa-github"></i>
                </a>
                <a class="btn btn-default btn-squar btn-lg btn-icon" href="https://twitter.com/BouraouiZiche" target="_blank" rel="tooltip" >
                    <i class="fa fa-twitter"></i>
                </a>
                <!--<a class="btn btn-default btn-squar btn-lg btn-icon" href="#" target="_blank" rel="tooltip" title="Follow me on Google+">
                    <i class="fa fa-google-plus"></i>
                </a>-->
             </div>
                   <!---->
            <button id="nav-btn" class="navbar-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-bar bar1"></span>
              <span class="navbar-toggler-bar bar2"></span>
              <span class="navbar-toggler-bar bar3"></span>
            </button>
            </div>
<!--test-->
<!--<div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#top" data-toggle="collapse" data-target=".navbar-collapse.show">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#top" data-toggle="collapse" data-target=".navbar-collapse.show">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#top" data-toggle="collapse" data-target=".navbar-collapse.show">Disabled</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form>
  </div>-->
<!---->

                <div class="collapse navbar-collapse justify-content-end" id="navigation">
                    <ul  class="navbar-nav">
                        <li class="nav-item" >
                            <a  class="nav-link smooth-scroll"  (click)="goto()"  routerLink='/about'> {{aboutTitle}} </a>
                        </li>
                        <li class="nav-item" >
                            <a  class="nav-link smooth-scroll"  (click)="goto()"  routerLink='/service'> {{serviceTitle}} </a>
                        </li>
                        <li class="nav-item" >
                            <a  class="nav-link smooth-scroll"  (click)="goto()"  routerLink='/experience'>{{experienceTitle}} </a>
                        </li>
                        <li class="nav-item">
                            <a  class="nav-link smooth-scroll"  (click)="goto()"  routerLink='/projects'> {{projectsTitle}} </a>
                        </li>
                        <li class="nav-item">
                            <a  class="nav-link smooth-scroll" (click)="goto()"  routerLink='/skills' >{{skillsTitle}} </a>
                        </li>
                        <li class="nav-item">
                            <a  class="nav-link smooth-scroll" (click)="goto()"  routerLink='/education' > {{educationTilte}} </a>
                        </li>
                        <!--<li class="nav-item">
                            <a routerLink='/reference' class="nav-link smooth-scroll" href="#reference">Reference</a>
                        </li>-->
                        <li class="nav-item">
                            <a  class="nav-link smooth-scroll" (click)="goto()"  routerLink='/contact' > {{contactTitle}} </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link smooth-scroll" > 
                                <img (click)="useLanguage('en')" class="langue" src="assets/images/english.png" alt="Image" />
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link smooth-scroll" > 
                                <img (click)="useLanguage('fr')" class="langue" src="assets/images/french.png" alt="Image" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</header>