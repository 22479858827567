import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
basicInfo  
about1
about2
langue
aboutTitle
constructor(private profileService:ProfileService,
            private translate:TranslateService) { }

  ngOnInit() {

    this.translate.get("about1").subscribe((res: string) => {
      //console.log(res);
      this.about1=res;      
    }); 

    this.translate.get("basicInfo").subscribe((res: string) => {
      //console.log(res);
      this.basicInfo=res;      
    });
    
    this.translate.get("about2").subscribe((res: string) => {
      //console.log(res);
      this.about2=res;      
    });

    this.translate.get("langue").subscribe((res: string) => {
      //console.log(res);
      this.langue=res;      
    });

    this.translate.get("aboutTitle").subscribe((res: string) => {
      //console.log(res);
      this.aboutTitle=res;      
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // console.log("event", event)
       this.translate.use(event.lang)

       this.translate.get("about1").subscribe((res: string) => {
        //console.log(res);
        this.about1=res;      
       });
      
      this.translate.get("about2").subscribe((res: string) => {
        //console.log(res);
        this.about2=res;      
      });

      this.translate.get("langue").subscribe((res: string) => {
        //console.log(res);
        this.langue=res;      
      });

      this.translate.get("aboutTitle").subscribe((res: string) => {
        //console.log(res);
        this.aboutTitle=res;      
      });

      this.translate.get("basicInfo").subscribe((res: string) => {
        //console.log(res);
        this.basicInfo=res;      
      });
  
 });
  
   // this.about1 =  this.profileService.about
   // this.about2 =  this.profileService.about2
  }

}
