
<app-header></app-header>
<div class="page-content">
    
<app-intro></app-intro>
<router-outlet id="contentView"></router-outlet>
<app-chatbot [isOpen]="isOpen"  ></app-chatbot>
</div>
<app-footer ></app-footer>

<ngx-spinner bdColor="rgba(255,255,255,1)" size="medium" color="#033d5b" type="ball-spin-clockwise"></ngx-spinner>

