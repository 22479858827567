<div class="section" id="about">
    <div class="container">
      <div class="card" data-aos="fade-up" data-aos-offset="10">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="card-body">
              <div class="h4 mt-0 title">{{aboutTitle}}</div>
               <div>
                <ul>
                  <li *ngFor="let m of about2 | split">{{m}}</li>
                </ul>
              </div>
                <p class="proverb" style="color: rgb(87, 180, 179);" >{{about1}}</p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="card-body">
              <div class="h4 mt-0 title">{{basicInfo}}</div>
              <div class="row">
             <div class="col-sm-4">
                  <strong class="text-uppercase">Skype:</strong>
                </div>
                <div class="col-sm-8">live:bouraoui.zich</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Email:</strong>
                </div>
                <div class="col-sm-8">bouraoui.zich@gmail.com</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Phone:</strong>
                </div>
                <div class="col-sm-8">+32 492 34 41 13</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Address:</strong>
                </div>
                <div class="col-sm-8">Brussels, Belgium
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Language:</strong>
                </div>
                <div class="col-sm-8">{{langue}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-service></app-service>
    </div>

  </div>
 