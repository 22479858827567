import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import { Observable, Subscription } from 'rxjs';
import { SnotifyPosition, SnotifyService, SnotifyToastConfig } from 'ng-snotify';
import { environment } from '../../../environments/environment';
import { HttpService } from "../Shared/http.service";

import { FormControl, Validators } from '@angular/forms';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';

//import { ToastrService } from 'ngx-toastr';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import emailjs from '@emailjs/browser';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  style = 'material';
  title = 'Snotify title!';
  body = 'Lorem ipsum dolor sit amet!';
  timeout = 3000;
  position: SnotifyPosition = SnotifyPosition.rightBottom;
  progressBar = true;
  closeClick = true;
  newTop = true;
  filterDuplicates = false;
  backdrop = -1;
  dockMax = 8;
  blockMax = 6;
  pauseHover = true;
  titleMaxLength = 15;
  bodyMaxLength = 80;
 
    loading = false;
    buttionText ;

      name:string= ""
      email:string = ""
      subject:string = ""
      message:string= ""

  
    emailFormControl = new FormControl("", [
      Validators.required,
      Validators.email
    ]);
  
    nameFormControl = new FormControl("", [
      Validators.required,
      Validators.minLength(4)
    ]);

    subjectFormControl = new FormControl("", [
      Validators.required,
      Validators.minLength(4)
    ]);

    messageFormControl = new FormControl("", [
      Validators.required,
      Validators.minLength(4)
    ]);

  

   snotifyConfig = environment.snotifyConfig;
   model: any = {};

  Name4charchter
  Nameis
  Subject4character
  Subjectis

  ValidEmail
  Emailis

  Message4charcters
  Messageis

  Required

  SayHello

  SucessMsg

  Success
  Warning
   constructor(
     private profile : ProfileService,
     private snotify : SnotifyService,
     public http : HttpService,
     private translate:TranslateService,
     private recaptchaV3Service: ReCaptchaV3Service
   ) { }

  ngOnInit() {

  console.log(this.http.test);
  
  this.translate.get("Success").subscribe((res: string) => {
    this.Success=res;      
  });

  this.translate.get("SucessMsg").subscribe((res: string) => {
    this.SucessMsg=res;      
  });

  this.translate.get("Name4charchter").subscribe((res: string) => {
   
    this.Name4charchter=res;      
  });

  this.translate.get("Nameis").subscribe((res: string) => {
   
    this.Nameis=res;      
  });

  this.translate.get("Subject4character").subscribe((res: string) => {
   
    this.Subject4character=res;      
  });

  this.translate.get("Subjectis").subscribe((res: string) => {
    
    this.Subjectis=res;      
  });

  this.translate.get("ValidEmail").subscribe((res: string) => {
    
    this.ValidEmail=res;      
  });

  this.translate.get("Emailis").subscribe((res: string) => {
   
    this.Emailis=res;      
  });

  this.translate.get("Message4charcters").subscribe((res: string) => {
    this.Message4charcters=res;      
  });

  this.translate.get("Messageis").subscribe((res: string) => {
    this.Messageis=res;      
  });

  this.translate.get("Required").subscribe((res: string) => {
    this.Required=res;      
  });
  

  this.translate.get("SayHello").subscribe((res: string) => {
    this.SayHello=res;      
  });

  this.translate.get("buttionText").subscribe((res: string) => {
    this.buttionText=res;      
  });

  this.translate.get("Warning").subscribe((res: string) => {
    this.Warning=res;      
  });
  

  this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
     this.translate.use(event.lang)

     
  this.translate.get("Name4charchter").subscribe((res: string) => {
    this.Name4charchter=res;      
  });

  this.translate.get("Nameis").subscribe((res: string) => {
    this.Nameis=res;      
  });

  this.translate.get("Subject4character").subscribe((res: string) => {
    this.Subject4character=res;      
  });

  this.translate.get("Subjectis").subscribe((res: string) => {
    this.Subjectis=res;      
  });

  this.translate.get("ValidEmail").subscribe((res: string) => {
    this.ValidEmail=res;      
  });

  this.translate.get("Emailis").subscribe((res: string) => {
    this.Emailis=res;      
  });

  this.translate.get("Message4charcters").subscribe((res: string) => {
    this.Message4charcters=res;      
  });

  this.translate.get("Messageis").subscribe((res: string) => {
    this.Messageis=res;      
  });

  this.translate.get("Required").subscribe((res: string) => {
    this.Required=res;      
  });

  this.translate.get("SayHello").subscribe((res: string) => {
    this.SayHello=res;      
  });
        
  this.translate.get("buttionText").subscribe((res: string) => {
    this.buttionText=res;      
  });

  this.translate.get("SucessMsg").subscribe((res: string) => {
    this.SucessMsg=res;      
  });

  this.translate.get("Success").subscribe((res: string) => {
    this.Success=res;      
  });

  this.translate.get("Warning").subscribe((res: string) => {
    this.Warning=res;      
  });

     });
   }





   getConfig(): SnotifyToastConfig {
    this.snotify.setDefaults({
      global: {
        newOnTop: this.newTop,
        maxAtPosition: this.blockMax,
        maxOnScreen: this.dockMax,
        // @ts-ignore
        filterDuplicates: this.filterDuplicates
      }
    });
    return {
      bodyMaxLength: this.bodyMaxLength,
      titleMaxLength: this.titleMaxLength,
      backdrop: this.backdrop,
      position: this.position,
      timeout: this.timeout,
      showProgressBar: this.progressBar,
      closeOnClick: this.closeClick,
      pauseOnHover: this.pauseHover
    };
  }


 
  submitted = false;
  reCAPTCHAToken: string = "";
  tokenVisible: boolean = false;
  reCAPTCHATokenF:any = ""
  private singleExecutionSubscription: Subscription;
   contact() {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service.execute('form').subscribe((token: string) => {
      this.tokenVisible = true;
      this.reCAPTCHAToken = `Token [${token}] generated`;
     
      this.reCAPTCHATokenF = "Action well validated by reCAPTCHA";
      this.loading = true;
    
      this.translate.get("Submiting").subscribe((res: string) => {
      this.buttionText=res;      
    });
    
    let user = {
      name : this.nameFormControl.value,
      email : this.emailFormControl.value,
      subject : this.subjectFormControl.value,
      message : this.messageFormControl.value     
    }
    /*** */
    const templateParams = {
      from_name: user.name,
      to_name: "Bouraoui",
      from_email: user.email,
      subject: user.subject,
      message: user.message
    };
    emailjs.init("EPHLSNeMLbnFWwD0c");
    emailjs.send("service_0emm5im","template_e106ow8",templateParams).then((response) => {
        let res:any = response; 
        this.loading = false;
        this.snotify.success(this.SucessMsg , this.Success, this.getConfig());
        this.name = ""
        this.email = ""
        this.subject = ""
        this.message = ""
        this.translate.get("buttionText").subscribe((res: string) => {
           this.buttionText=res;      
        });
        console.log('SUCCESS!', response.status, response.text);
    }, (err) => {
      console.log('FAILED...', err);
      this.snotify.warning("An error occurred, please try later.",this.Warning, this.getConfig());
       this.loading = false;
       this.translate.get("buttionText").subscribe((res: string) => {
       this.buttionText=res;      
       });
    });
  },
  (error) => {
    this.reCAPTCHAToken = "";
    console.log(error)
  },);
  }

  public ngOnDestroy(): void {

    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  public formatToken(token: string): string {
    if (!token) {
      return "(empty)";
    }

    return `${token.substr(0, 7)}...${token.substr(-7)}`;
  }

}

