import { Component, OnInit } from '@angular/core';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  aboutTitle
  serviceTitle
  experienceTitle
  projectsTitle
  skillsTitle 
  educationTilte
  contactTitle

  param = {value: 'world'};

  constructor(private translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
   translate.setDefaultLang('en');
     // the lang to use, if the lang isn't available, it will use the current loader to get them
    //translate.use('fr');
  }
  useLanguage(lang)
  {
    this.translate.use(lang);
  }
  ngOnInit() {

      this.translate.get("aboutTitle").subscribe((res: string) => {
        //console.log(res);
        this.aboutTitle=res;      
      });
      this.serviceTitle = "SERVICES" 

      this.translate.get("experienceTitle").subscribe((res: string) => {
        //console.log(res);
        this.experienceTitle=res;      
      });

      this.translate.get("projectsTitle").subscribe((res: string) => {
        //console.log(res);
        this.projectsTitle=res;      
      });

      this.translate.get("skillsTitle").subscribe((res: string) => {
        //console.log(res);
        this.skillsTitle=res;      
      });

      this.translate.get("educationTilte").subscribe((res: string) => {
        //console.log(res);
        this.educationTilte=res;      
      });

      this.translate.get("contactTitle").subscribe((res: string) => {
        //console.log(res);
        this.contactTitle=res;      
      });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        // console.log("event", event)
          this.translate.use(event.lang)

          this.translate.get("aboutTitle").subscribe((res: string) => {
            //console.log(res);
            this.aboutTitle=res;      
          });
    
          this.translate.get("experienceTitle").subscribe((res: string) => {
            //console.log(res);
            this.experienceTitle=res;      
          });
    
          this.translate.get("projectsTitle").subscribe((res: string) => {
            //console.log(res);
            this.projectsTitle=res;      
          });
    
          this.translate.get("skillsTitle").subscribe((res: string) => {
            //console.log(res);
            this.skillsTitle=res;      
          });
    
          this.translate.get("educationTilte").subscribe((res: string) => {
            //console.log(res);
            this.educationTilte=res;      
          });
    
          this.translate.get("contactTitle").subscribe((res: string) => {
            //console.log(res);
            this.contactTitle=res;      
          });
  });

}

  goto() { 
  var element = document.getElementById("contentView");
  element.scrollIntoView();

  }

}




