<div class="section" id="projects">
    <div class="container cc-education">
        <div class="h4 text-center mb-4 title" style="color: black;">{{ProjectsTitle}}</div>

        <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
        <div class="row">

            <ng-container *ngFor="let project of projects | paginate: config ">
                <div class="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft">
                    <div class="blog-entry">
                        <div class="card">
                            <ng-container *ngIf="project.id==1 ; else second">
                                <iframe class="card-img-top" src="https://www.youtube.com/embed/SHedOrQxldo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
                            </ng-container> 

                            <ng-template #second >
                                <ng-container *ngIf="project.id==2 ; else thursd">
                                <iframe class="card-img-top" src="https://www.youtube.com/embed/E1QJzXuDXKg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                 </ng-container>
                            </ng-template> 

                            <ng-template #thursd  >
                                <ng-container *ngIf="project.id==3 ; else fourth">
                                <iframe  class="card-img-top"  src="https://www.youtube.com/embed/9ZKDSzzRcQw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </ng-container>
                            </ng-template>
     
                            <ng-template #fourth >
                                <ng-container *ngIf="project.id==4 ; else fifth">
                                <iframe class="card-img-top" src="https://www.youtube.com/embed/60GOpoBcVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </ng-container>
                            
                            </ng-template>

                            <ng-template #fifth  >
                                <iframe class="card-img-top" src="https://www.youtube.com/embed/7CkcnxFvk_8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </ng-template>

                            <!-- <iframe class="card-img-top" src={{project.imgUrl}} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                          
                           <!--<iframe class="card-img-top" src="https://www.youtube.com/embed/SHedOrQxldo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                           <!-- <img class="card-img-top" src={{project.imgUrl}} onerror="this.src = 'assets/images/gif.gif'" alt="Card image" style="width:100%"> -->
                            <div class="card-body">
                                <h4 class="card-title">{{project.title}}</h4>
                                <h5 class="card-text"><b>{{Technology}} :</b> {{project.tech}} </h5>
                                <!--<h5 class="card-text"><a href={{project.mediumlink}}>Read Blog on this  Project</a></h5>-->
                                <!--<a href={{project.livedemo}} class="btn btn-primary stretched-link ml-2 mr-5">Live Demo</a>-->
                                <!--<a href={{project.githurl}} class="btn btn-primary stretched-link">GithubLink</a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
</div>