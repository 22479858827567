<div class="section" id="contact">
        <div class="cc-contact-information" style="background-image: url('assets/images/contact1.jpg');background-repeat: no-repeat;
        background-size: 100% 100%;">
          <div class="container" >
            <div class="cc-contact" >
              <div class="row" >
                <div class="col-md-9">
                  <div class="card mb-0" data-aos="zoom-in">
                    <!--<div class="h4 text-center title">Say Hi !</div>-->
                    <!--<div class="col-md-6">
                      <div class="card-body">
                        <p class="mb-0">
                          <strong>Phone</strong>
                        </p>
                        <p class="pb-2">+351 910 176 183</p>
                        <p class="mb-0">
                          <strong>Email</strong>
                        </p>
                        <p>bouraoui.zich@gmail.com</p>
                      </div>
                    </div>-->
                    
                    <p style='color: rgb(21, 70, 95);' class="h4 text-center text">bouraoui.ziche@gmail.com</p>
                    <p style='color: rgb(21, 70, 95);' class="h4 text-center title"> {{SayHello}} </p>
                    <div class="row">
                      <!--<div class="col-md-9">-->
                        <div class="card-body">
                      <form name="form" (ngSubmit)="f.form.valid ; f.resetForm()" #f="ngForm" >
                       <!--<div style='padding-left: 35%;' class="p pb-3">-->
                         
                        
                            <div class="row mb-9">
                              <div class="col">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <i class="fa fa-user-circle"></i>
                                  </span>
                                  <input class="form-control" type="text"  [(ngModel)]="name" [formControl]="nameFormControl" name="name" placeholder="Name"
                                     />
                                    <mat-error class="form-control" *ngIf="nameFormControl.hasError('minlength')">
                                    {{Name4charchter}}
                                    </mat-error>
                                    <mat-error class="form-control" *ngIf="nameFormControl.hasError('required')">
                                      {{Nameis}} &nbsp;<strong> {{Required}}</strong>
                                    </mat-error>

                                </div>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <i class="fa fa-file-text"></i>
                                  </span>
                                  <input class="form-control" type="text" [(ngModel)]="subject" name="subject" [formControl]="subjectFormControl" placeholder="Subject"
                                    />
                                    <mat-error class="form-control" *ngIf="subjectFormControl.hasError('minlength')">
                                    {{Subject4character}}
                                    </mat-error>
                                    <mat-error class="form-control" *ngIf="subjectFormControl.hasError('required')">
                                      {{Subjectis}}&nbsp; <strong> {{ Required}}</strong>
                                    </mat-error>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <i class="fa fa-envelope"></i>
                                  </span>
                                  <input class="form-control" type="text" [(ngModel)]="email" name="email" [formControl]="emailFormControl" placeholder="E-mail" />
                                    <mat-error class="form-control" *ngIf="emailFormControl.hasError('email')">
                                      {{ValidEmail}}
                                    </mat-error>
                                    <mat-error class="form-control" *ngIf="emailFormControl.hasError('required')">
                                      {{Emailis}} &nbsp; <strong> {{Required}}</strong>
                                    </mat-error>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col">
                                <div class="form-group">
                                  <textarea class="form-control" [formControl]="messageFormControl" [(ngModel)]="message" name="message" placeholder="Your Message"></textarea>
                                    <mat-error class="form-control" *ngIf="messageFormControl.hasError('minlength')">
                                      {{Message4charcters}}
                                    </mat-error>
                                    <mat-error class="form-control" *ngIf="messageFormControl.hasError('required')">
                                      {{Messageis}} <strong> {{Required}}</strong>
                                    </mat-error>
                                    
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <!--<button class="btn btn-primary" type="submit">Send</button>-->
                                <button   class="btn btn-primary"
                                          mat-raised-button 
                                          [disabled]="emailFormControl.invalid || subjectFormControl.invalid || nameFormControl.invalid || messageFormControl.invalid ||  loading"
                                          color="primary"
                                          (click)="contact()">
                                {{buttionText}}
                                </button>
                              </div>
                            </div>
                            <div *ngIf="tokenVisible"> reCAPTCHA Token <br />
                              <p style="color:rgb(17, 99, 6)">{{reCAPTCHATokenF}}</p>
                            </div>
                          </form>
                          
                         <ng-snotify class="material"></ng-snotify>
                       
                        <!--</div>-->
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>


    