<div class="section" id="education">
    <div class="container cc-education">
      <div class="h4 text-center mb-4 title" style="color:black">{{Education}}</div>
      <div class="card">
        <div class="row">
          <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body cc-education-header">
              <p>2016 - 2019</p>
              <div class="h5">{{CSED}}</div>
            </div>
          </div>
          <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body">
              <div class="h5">{{CSE}}</div>
               <h4 class="category">{{PHSEME}}
               </h4>
               <ul>
                 <li>{{LI1CSE}}</li>
                 <li>{{LI2CSE}}</li>
                 <li>{{LI3CSE}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="row">
          <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body cc-education-header">
              <p>2014 - 2016</p>
              <div class="h5">{{TCP}}</div>
            </div>
          </div>
          <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body">
              <div class="h5">{{TCP}}</div>
               <h4 class="category">{{PHSEME}}
               </h4>
               <ul>
                <li>
                  {{LI1TCP}} 
                </li>           
                <li>
                  {{LI2TCP}}
                </li>
                <li>
                  {{LI3TCP}}
                </li>
             </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="row">
          <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body cc-education-header">
              <p>2011 - 2012</p>
              <div class="h5">{{BDTS}}</div>
            </div>
          </div>
          <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body">
              <div class="h5">{{BDTS}}</div>
               <h4 class="category">{{HSKM}}
               </h4>
               <ul>
                <li>
                  {{LI1HSKM}}
                 </li>               
                <li>
                  {{LI2HSKM}}
                </li>
             </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Certificate Bloc to be added here  -->
  <app-certificate></app-certificate>