
 
    <app-about></app-about>
   
   <!-- <app-experience></app-experience>
    <app-projects></app-projects>
    <app-skills></app-skills>
    <app-education></app-education>
    
     <app-portfolio></app-portfolio> 
    <app-reference></app-reference> 
    <app-contact></app-contact>-->
    
    
