import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  skills
  otherskills

  SkillsTitle
  OtherSkillsTitle
  constructor(private profileService:ProfileService,
              private translate:TranslateService) { }
  
 ngOnInit() {
     
      this.translate.get("SkillsTitle").subscribe((res: string) => {
        //console.log(res);
        this.SkillsTitle=res;    
    });

    this.translate.get("OtherSkillsTitle").subscribe((res: string) => {
      //console.log(res);
      this.OtherSkillsTitle=res;    
    });

    this.translate.get("OtherskillsData").subscribe((res: string) => {
      //console.log(res);
      this.otherskills=res;    
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // console.log("event", event)
       this.translate.use(event.lang)
       this.translate.get("SkillsTitle").subscribe((res: string) => {
        //console.log(res);
        this.SkillsTitle=res;    
    });

    this.translate.get("OtherSkillsTitle").subscribe((res: string) => {
      //console.log(res);
      this.OtherSkillsTitle=res;    
  });
  
  this.translate.get("OtherskillsData").subscribe((res: string) => {
    //console.log(res);
    this.otherskills=res;    
  });


    });
      this.skills =  this.profileService.skills()

      
     // this.otherskills =  this.profileService.otherskills()
    }

}
