import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  workexp
  WorkExperience
  constructor(private profileService:ProfileService,
              private translate:TranslateService) { }
   
    ngOnInit() {    
      
      this.translate.get("exprienceData").subscribe((res: string) => {        
        this.workexp=res;      
      }); 

      this.translate.get("WorkExperience").subscribe((res: string) => {        
        this.WorkExperience=res;      
      }); 



      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        // console.log("event", event)
         this.translate.use(event.lang)  

         this.translate.get("WorkExperience").subscribe((res: string) => {        
          this.WorkExperience=res;      
        }); 
         this.translate.get("exprienceData").subscribe((res: string) => {
          //console.log(res);
          this.workexp=res;      
         });

      });
      //this.workexp =  this.profileService.exprience()
    }
}
