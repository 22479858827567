<div class="section" id="experience">
    <div class="container cc-education">
    <h2>My Services</h2>
    <section class="services">
        <div class="card">
            <div class="content">
                <div class="icon"><i class="fa fa-code"></i></div>
                <div class="title">Web Development</div>
                <p>Enhance your online presence with my expert web development service. Specializing in user-friendly websites and cutting-edge technologies, I ensure a seamless digital experience. Whether launching a new site or enhancing an existing one, I cover design and functionality. Elevate your online success with my professional solutions.</p>
            </div>
        </div>
        <div class="card">
            <div class="content">
                <div class="icon"><i class="fa fa-coffee"></i></div>
                <div class="title">APIs</div>
                <p>Optimize your website with my API service. I specialize in integrating powerful APIs for seamless communication between software components. Whether it's data integration, third-party services, or enhanced functionality, trust my expertise to streamline and elevate your website effectively.</p>
            </div>
        </div>
        <div class="card">
            <div class="content">
                <div class="icon"><i class="fa fa-paint-brush"></i></div>
                <div class="title">Web Design </div>
                <p>Transform your online presence with my captivating web design service. I craft stunning, user-friendly designs to elevate your brand and create a memorable online experience. Let me enhance your website's visual appeal and user engagement with my professional touch. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </div>
        </div>
        <div class="card">
            <div class="content">
                <div class="icon"><i class="fa fa-laptop"></i></div>
                <div class="title">Responsive Design </div>
                <p>Enhance your website's adaptability with my responsive design service. I ensure seamless adaptation to various screen sizes for optimal user experiences on desktops, tablets, and smartphones. Elevate accessibility and engagement by making your site visually appealing and functional across all devices with my expert solutions.</p>
            </div>
        </div>
        <div class="card">
            <div class="content">
                <div class="icon"><i class="fa fa-line-chart"></i></div>
                <div class="title">Advertising </div>
                <p>Enhance your online presence with my tailored advertising service. I specialize in targeted campaigns, ensuring effective audience reach from planning to execution. Deliver your brand message across platforms, maximizing visibility. Elevate your digital reach with my professional advertising solutions.</p>
            </div>
        </div>
        <!-- div class="card">
            <div class="content">
                <div class="icon"><i class="fa fa-wordpress"></i></div>
                <div class="title">Wordpress</div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, reprehenderit.</p>
            </div>
        </div-->
    </section>
</div>
</div>
