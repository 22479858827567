<footer class="footer">
    <div class="container text-center">
      <a class="cc-facebook btn btn-link" href="https://www.facebook.com/monster.leebruce/" target="_blank">
        <i class="fa fa-facebook fa-2x " aria-hidden="true"></i>
      </a>
      <a class="cc-instagram btn btn-link" href="https://www.instagram.com/bouraouiziche/" target="_blank">
        <i class="fa fa-instagram fa-2x " aria-hidden="true"></i>
      </a>
    
      <a class="cc-linkedin btn btn-link " href="https://www.linkedin.com/in/bouraoui-ziche/" target="_blank">
        <i class="fa fa-linkedin fa-2x " aria-hidden="true"></i>
      </a>

      <a class="cc-github btn btn-link " href="https://github.com/sag3ellil" target="_blank">
        <i class="fa fa-github fa-2x " aria-hidden="true"></i>
      </a>

      <a class="cc-twitter btn btn-link " href="https://twitter.com/BouraouiZiche" target="_blank">
        <i class="fa fa-twitter fa-2x " aria-hidden="true"></i>
      </a>
   
      <!-- <a class="cc-google-plus btn btn-link" href="#" target="_blank">
        <i class="fa fa-google-plus fa-2x" aria-hidden="true"></i>
      </a> -->
    </div>
    <div class="text-center text-muted">
      <p> {{copyright}} 2021-2024 &copy; Bouraoui Ziche | {{Allrightsreserved}}
      </p>
    </div>
  </footer>