<div class="section" id="certificate">
    <div class="container cc-education">
        <div class="h4 text-center mb-4 title" style="color: black;">{{certifSection}}</div>

       <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>-->
        <div class="row">

          
            <ng-container >  <!--*ngFor="let project of projects | paginate: config " -->
               
                
                <div class="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft" *ngFor="let image of _albums; let i=index">
                    <div class="blog-entry" >
                        <div class="card" data-toggle="modal" data-target="#exampleModalCenter"  >
                             <img [src]="image.thumb" (click)="open(i)" />

                           <!-- <img class="card-img-bottom" src="assets/certif/CourseraSpringBoot.jpg"  onerror="this.src = 'assets/images/loading.gif'" alt="Card image" style="width:100%;height: 100%;"> -->
                            <div class="card-body">
                                <h3 class="card-title" style="text-align: center;">{{image.title}}</h3>
                                <h3 class="card-text" style="text-align: center;"><b>Source : </b> {{image.source}} </h3>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>

      <!--  <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> -->
    </div>
</div> 

<!-- Modal -->
<!--<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">-->
         <!-- <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>-->
<!--          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <img  src="assets/certif/CourseraSpringBoot.jpg"  onerror="this.src = 'assets/images/loading.gif'" alt="Card image" style="width:100%;height: 100%;">
                          
        </div>-->
        <!--<div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>-->
 <!--     </div>
    </div>
  </div>-->

